import styled from '@emotion/styled';

export const TableStyle = styled.div`
  width: 100%;
  background-color: #f9fafb;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1),
    9px 9px 16px 0 rgba(0, 0, 0, 0.1), -4px -4px 8px 0 #fff,
    -9px -9px 16px 0 #fff;
  overflow-x: auto;

  table {
    border-spacing: 20px 0;
    border-collapse: collapse;
  }

  table,
  tr,
  th,
  td {
    text-align: left;
    margin: 0;
    border-left: 20px solid;
    border-color: transparent;
  }

  table > thead {
    color: #666;
    font-size: 14px;

    > tr {
      > th {
        padding: 10px;
        padding-left: 0;
        border: inherit;
      }
    }
  }

  tbody > tr {
    td {
      padding: 10px 0 10px 10px;
      margin-right: 20px;
      color: #2b2a30;
      font-size: 12px;
      border-bottom: 1px solid #ccc;
    }

    &:first-of-type(td) {
      padding-left: 20px;
      padding-right: 0;
    }
  }
`;
