import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';
import BasicTable from '@/components/Tables/BasicTable';
import { Button, H1, H3 } from '@/elements';
import {
  ReportModel,
  ReportStatus,
  useAssessmentCycleWithReports,
  useEmployerReport,
  useGenerateEmployerReport,
} from '@/generated/graphql';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import downloadFile from '@/utils/downloadFile';

const PrintReportsModal: FC<{
  assessmentCycleId: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ assessmentCycleId, isOpen, onClose }) => {
  const [newReportId, setNewReportId] = useState('');
  const [downloadError, setDownloadError] = useState('');
  const {
    data,
    refetch: refetchAssessment,
    loading: fetchingAssessment,
    startPolling,
    stopPolling,
  } = useAssessmentCycleWithReports({
    variables: {
      id: assessmentCycleId,
    },
  });
  const [generateReport] = useGenerateEmployerReport();
  const { refetch: refetchReport } = useEmployerReport({ skip: true });
  const { isMobileSafari } = useDeviceDetect();

  const downloadReport = useCallback(
    async (id: string) => {
      setDownloadError('');
      const res = await refetchReport({
        options: {
          id,
          assessmentCycleId: Number(assessmentCycleId),
        },
      });
      const url = res?.data.employerReport.downloadUrl;

      if (url) {
        downloadFile({ url, overrideCurrentPage: isMobileSafari });
      } else {
        setDownloadError(
          'There was a problem downloading your report. Please try again or contact support',
        );
      }
    },
    [assessmentCycleId, isMobileSafari, refetchReport],
  );

  const reports = (data?.assessmentCycle.reports as ReportModel[]) || [];

  useEffect(() => {
    const newReport = reports.find(r => r.id === newReportId);
    if (newReport && newReport.status !== ReportStatus.Requested) {
      stopPolling();
    }
  }, [reports, newReportId]);

  const handleGenerateNewReport = useCallback(async () => {
    const result = await generateReport({
      variables: {
        options: { assessmentCycleId: Number(assessmentCycleId) },
      },
    });
    refetchAssessment();

    if (result?.data.generateEmployerReport.report) {
      setNewReportId(result?.data.generateEmployerReport.report.id);
      startPolling(1000 * 5);
    }
  }, [newReportId]);

  const handleClose = useCallback(() => {
    onClose();
    stopPolling();
  }, []);

  const report = reports.find(r => r.id === newReportId);
  const tableColumns = [
    {
      Header: 'Requested At',
      accessor: d =>
        d.requestedAt ? moment(d.requestedAt).format('DD/MM/yy HH:mm') : 'N/A',
    },
    {
      Header: 'Created At',
      accessor: d =>
        d.createdAt ? moment(d.createdAt).format('DD/MM/yy HH:mm') : 'N/A',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Download Link',
      accessor: d =>
        d.gcsFile?.url ? (
          <Button
            color="yellow.400"
            onClick={() => downloadReport(d.id)}
            variant="link"
          >
            Download
          </Button>
        ) : (
          <Text color="red">{d.error}</Text>
        ),
    },
  ];

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent bg="white.400">
        <ModalHeader>
          <H1 mb="2" variant="dark">
            Print Report
          </H1>
        </ModalHeader>

        <ModalBody maxH={500} overflow="auto">
          {fetchingAssessment && (
            <>
              <LoadingSpinner variant="dark" />
              <H3 textAlign="center" variant="dark">
                Loading your report...
              </H3>
            </>
          )}

          {reports.length ? (
            <BasicTable columns={tableColumns} data={reports} />
          ) : (
            <H3 textAlign="center" variant="dark">
              No reports yet
            </H3>
          )}
          {downloadError && (
            <Flex
              alignItems="center"
              color="red"
              justifyContent="center"
              mt="md"
            >
              {downloadError}
            </Flex>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={report?.status === ReportStatus.Requested}
            mr={3}
            onClick={handleGenerateNewReport}
          >
            Generate new report
          </Button>

          <Button mr={3} onClick={handleClose} rightIcon={null}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintReportsModal;
