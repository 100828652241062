import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Body } from '@/elements';

const FormError: FC<BoxProps> = ({ children, ...rest }) => (
  <Box mb="3" minHeight="40px" {...rest}>
    <Body color="red.400">{children}</Body>
  </Box>
);

export default FormError;
