import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';
import BasicTable from '@/components/Tables/BasicTable';
import { Button, H1, H3 } from '@/elements';
import {
  ScorecardModel,
  ScorecardStatus,
  useAssessmentCycleWithScorecards,
  useEmployerScorecard,
  useGenerateEmployerScorecard,
} from '@/generated/graphql';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import downloadFile from '@/utils/downloadFile';

const PrintScorecardsModal: FC<{
  assessmentCycleId: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ assessmentCycleId, isOpen, onClose }) => {
  const [newScoreCardId, setNewScorecardId] = useState('');
  const [downloadError, setDownloadError] = useState('');
  const {
    data,
    refetch: refetchAssessment,
    loading: fetchingAssessment,
    startPolling,
    stopPolling,
  } = useAssessmentCycleWithScorecards({
    variables: {
      id: assessmentCycleId,
    },
  });
  const [generateScorecard] = useGenerateEmployerScorecard();
  const { refetch: refetchScorecard } = useEmployerScorecard({ skip: true });
  const { isMobileSafari } = useDeviceDetect();
  const downloadScorecard = useCallback(
    async (id: string) => {
      setDownloadError('');
      const res = await refetchScorecard({
        options: {
          id,
          assessmentCycleId: Number(assessmentCycleId),
        },
      });
      const url = res?.data.employerScorecard.downloadUrl;

      if (url) {
        downloadFile({ url, overrideCurrentPage: isMobileSafari });
      } else {
        setDownloadError(
          'There was a problem downloading your scorecard. Please try again or contact support',
        );
      }
    },
    [assessmentCycleId, isMobileSafari, refetchScorecard],
  );

  const scorecards =
    (data?.assessmentCycle.scorecards as ScorecardModel[]) || [];

  useEffect(() => {
    const newScorecard = scorecards.find(r => r.id === newScoreCardId);
    if (newScorecard && newScorecard.status !== ScorecardStatus.Requested) {
      stopPolling();
    }
  }, [scorecards, newScoreCardId]);

  const handleGenerateNewScorecard = useCallback(async () => {
    const result = await generateScorecard({
      variables: {
        options: { assessmentCycleId: Number(assessmentCycleId) },
      },
    });
    refetchAssessment();

    if (result?.data.generateEmployerScorecard.scorecard) {
      setNewScorecardId(result?.data.generateEmployerScorecard.scorecard.id);
      startPolling(1000 * 5);
    }
  }, [newScoreCardId]);

  const handleClose = useCallback(() => {
    onClose();
    stopPolling();
  }, []);

  const scorecard = scorecards.find(r => r.id === newScoreCardId);
  const tableColumns = [
    {
      Header: 'Requested At',
      accessor: d =>
        d.requestedAt ? moment(d.requestedAt).format('DD/MM/yy HH:mm') : 'N/A',
    },
    {
      Header: 'Created At',
      accessor: d =>
        d.createdAt ? moment(d.createdAt).format('DD/MM/yy HH:mm') : 'N/A',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Download Link',
      accessor: d =>
        d.gcsFile?.url ? (
          <Button
            color="yellow.400"
            onClick={() => downloadScorecard(d.id)}
            variant="link"
          >
            Download
          </Button>
        ) : (
          <Text color="red">{d.error}</Text>
        ),
    },
  ];

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.500" />
      <ModalContent bg="white.400">
        <ModalHeader>
          <H1 mb="2" variant="dark">
            Print Scorecards
          </H1>
        </ModalHeader>

        <ModalBody maxH={500} overflow="auto">
          {fetchingAssessment && (
            <>
              <LoadingSpinner variant="dark" />
              <H3 textAlign="center" variant="dark">
                Loading your scorecard...
              </H3>
            </>
          )}

          {scorecards.length ? (
            <BasicTable columns={tableColumns} data={scorecards} />
          ) : (
            <H3 textAlign="center" variant="dark">
              No scorecards yet
            </H3>
          )}
          {downloadError && (
            <Flex
              alignItems="center"
              color="red"
              justifyContent="center"
              mt="md"
            >
              {downloadError}
            </Flex>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={scorecard?.status === ScorecardStatus.Requested}
            mr={3}
            onClick={handleGenerateNewScorecard}
          >
            Generate new scorecard
          </Button>

          <Button mr={3} onClick={handleClose} rightIcon={null}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintScorecardsModal;
