import { Box, Skeleton } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTable, useSortBy, useExpanded } from 'react-table';

import { TableStyle } from '../styled';

export default function ({
  data,
  columns,
  loading = false,
  renderRowSubComponent = null,
  className = '',
}) {
  const memoData = useMemo(() => data, [data]);
  const memoColumns = useMemo(() => columns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    { columns: memoColumns, data: memoData },
    useSortBy,
    useExpanded,
  );

  return (
    <TableStyle {...getTableProps()} className={className}>
      <table>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: column.width }}
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
                {renderRowSubComponent && row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
          {!rows.length &&
            loading &&
            headerGroups.map(headerGroup => (
              <tr>
                {headerGroup.headers.map(() => (
                  <Box as="td" style={{ padding: 0 }}>
                    <Skeleton height={30} />
                  </Box>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </TableStyle>
  );
}
