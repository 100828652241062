import { Box, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { GoCalendar } from 'react-icons/go';

import ReportRequestForm from './ReportRequestForm';
import { NavContainer, NavLink, NavLinkWithIcon } from '../styled';

import Modal from '@/components/Modal';
import PrintReportsModal from '@/components/PrintReportsModal';
import PrintScorecardsModal from '@/components/PrintScorecardModal';
import useLazyEmployeesCount from '@/hooks/useLazyEmployeesCount';

const EmployerNav = () => {
  const router = useRouter();
  const { hasUncompletedAssessments } = useLazyEmployeesCount();
  const { assessmentCycleId } = router.query;
  const {
    isOpen: isRequestModalOpen,
    onOpen: onRequestModalOpen,
    onClose: onRequestModalClose,
  } = useDisclosure();
  const {
    isOpen: isReportsModalOpen,
    onOpen: onReportsModalOpen,
    onClose: onReportsModalClose,
  } = useDisclosure();

  const {
    isOpen: isScorecardModalOpen,
    onOpen: onScorecardModalOpen,
    onClose: onScorecardModalClose,
  } = useDisclosure();

  const routes = useMemo(
    () => [
      {
        to: '/employer/dashboard',
        title: 'Dashboard',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/productivity-analysis`,
        title: 'Productivity Analysis',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/wellbeing-profile`,
        title: 'Wellbeing Profile',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/wellbeing-priorities`,
        title: 'Wellbeing Priorities',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/wellbeing-analysis`,
        title: 'Wellbeing Analysis',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/variance-analysis`,
        title: 'Variance Analysis',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/demographics-data`,
        title: 'Demographics Data',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/cultural-voice`,
        title: 'Cultural Voice',
      },
      {
        to: `/employer/assessment-cycles/${assessmentCycleId}/report/ocean-profile`,
        title: 'OCEAN Profile',
      },
    ],
    [assessmentCycleId],
  );

  const indexSelected = routes.findIndex(x => router.asPath.indexOf(x.to) > -1);

  const iconLinks = [
    {
      icon: GoCalendar,
      onClick: onRequestModalOpen,
      text: 'Request a report',
      isDisabled: false,
    },
    {
      icon: AiOutlinePrinter,
      onClick: onReportsModalOpen,
      text: 'Download report',
      isDisabled: hasUncompletedAssessments,
    },
    {
      icon: AiOutlinePrinter,
      onClick: onScorecardModalOpen,
      text: 'Download Scorecard',
      isDisabled: false,
    },
  ];

  return (
    <>
      <NavContainer>
        {indexSelected > -1 && (
          <Box
            backgroundColor="green.500"
            borderRadius="10px"
            height="20px"
            left="-70px"
            position="absolute"
            transform={`translateY(${indexSelected * 38}px)`}
            transition="transform 300ms ease-in-out"
            width="20px"
          />
        )}
        <Box mb="48px" zIndex="10">
          {routes.map(route => (
            <NavLink
              href={route.to}
              key={route.title}
              selected={router.asPath === route.to}
              variant="green"
            >
              {route.title}
            </NavLink>
          ))}
        </Box>

        <Box>
          {iconLinks.map(({ text, icon, onClick, isDisabled }) => (
            <NavLinkWithIcon
              icon={icon}
              isDisabled={isDisabled}
              onClick={onClick}
              variant="green"
            >
              {text}
            </NavLinkWithIcon>
          ))}
        </Box>
      </NavContainer>

      <Modal
        isOpen={isRequestModalOpen}
        onClose={onRequestModalClose}
        title="Request a report"
      >
        <ReportRequestForm assessmentCycleId={assessmentCycleId} />
      </Modal>
      <PrintReportsModal
        assessmentCycleId={assessmentCycleId as string}
        isOpen={isReportsModalOpen}
        onClose={onReportsModalClose}
      />
      <PrintScorecardsModal
        assessmentCycleId={assessmentCycleId as string}
        isOpen={isScorecardModalOpen}
        onClose={onScorecardModalClose}
      />
    </>
  );
};

export default EmployerNav;
