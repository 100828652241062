import { Flex } from '@chakra-ui/react';
import { AiOutlineCheck } from 'react-icons/ai';

import { Body, Button } from '@/elements';

interface ISuccessSection {
  successMessage: string;
  ctaLabel?: string;
  onCtaClicked?: () => void;
}

export default function SuccessSection({
  successMessage,
  ctaLabel,
  onCtaClicked,
}: ISuccessSection) {
  return (
    <Flex alignItems="center" direction="column" margin="48px 0px">
      <Flex
        alignItems="center"
        backgroundColor="green.500"
        borderRadius="100%"
        h="10vw"
        justifyContent="center"
        w="10vw"
      >
        <AiOutlineCheck color="white" size="7vw" />
      </Flex>
      <Body fontSize="18px" marginY="24px">
        {successMessage}
      </Body>
      {ctaLabel && onCtaClicked && (
        <Button colorScheme="green" onClick={onCtaClicked}>
          {ctaLabel}
        </Button>
      )}
    </Flex>
  );
}
