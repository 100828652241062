import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormError from '@/components/Form/FormError';
import FormWrapper from '@/components/Form/FormWrapper';
import LoadingSpinner from '@/components/LoadingSpinner';
import SuccessSection from '@/components/SuccessSection';
import { Button, TextArea, TextField } from '@/elements';
import {
  useAssesmentCycleWithReportRequest,
  useRequestReport,
} from '@/generated/graphql';
import useYupValidationResolver from '@/hooks/useYupValidationResolver';

const ReportRequestForm = ({ assessmentCycleId }) => {
  const [submitError, setSubmitError] = useState('');
  const {
    data: assessmentCycle,
    loading,
    error,
  } = useAssesmentCycleWithReportRequest({
    variables: {
      id: assessmentCycleId,
    },
  });
  const validationSchema = useMemo(
    () =>
      yup.object({
        subject: yup.string().required('Please enter a subject'),
        request: yup.string().required('Please write a message'),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const [requestReport, { loading: submitting }] = useRequestReport();
  const { handleSubmit, control, register, errors } = useForm({ resolver });
  const onSubmit = useCallback(async ({ subject, request }) => {
    try {
      const { data } = await requestReport({
        variables: {
          id: assessmentCycleId,
          request: {
            dateRequested: new Date(),
            subject,
            request,
          },
        },
      });

      if (data?.requestReport?.error) {
        setSubmitError(data.requestReport.error);
      }
    } catch (ex) {
      setSubmitError(ex.message);
    }
  }, []);

  if (error) {
    return <FormError>{error.message}</FormError>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (assessmentCycle?.assessmentCycle?.reportRequest) {
    return (
      <SuccessSection successMessage="Your request has been sent. We'll get back to you shortly" />
    );
  }

  return (
    <FormWrapper fullWidth onSubmit={handleSubmit(onSubmit)}>
      <TextField
        color="black"
        colorScheme="green"
        errors={errors}
        id="subject-input"
        mb="lg"
        name="subject"
        placeholder="Subject"
        register={register}
      />
      <TextArea
        color="black"
        colorScheme="green"
        register={register}
        errors={errors}
        id="body-input"
        minLength={3}
        name="request"
        placeholder="Content"
        rows={4}
      />
      <FormError>{submitError}</FormError>
      <Button
        alignSelf="flex-end"
        colorScheme="green"
        isLoading={submitting}
        marginTop="24px"
        type="submit"
      >
        Submit
      </Button>
    </FormWrapper>
  );
};

export default ReportRequestForm;
