import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import config from '../config';
import { useAssesmentCycleWithEmployeeParticipationLazyQuery } from '../generated/graphql';

export default function useLazyEmployeesCount() {
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [rawDate, setRawDate] = useState<Date>();

  const [fetchData, { data, loading, error }] =
    useAssesmentCycleWithEmployeeParticipationLazyQuery();
  const {
    query: { assessmentCycleId },
  } = useRouter();

  useEffect(() => {
    if (assessmentCycleId) {
      fetchData({ variables: { id: assessmentCycleId as string } });
    }
  }, [assessmentCycleId]);

  useEffect(() => {
    if (data) {
      const {
        assessmentCycle: {
          updatedAt,
          participationResults: {
            totalEmployeeCount,
            completionRate,
            completedEmployeeCount,
          },
        },
      } = data;

      setTotalEmployees(totalEmployeeCount);
      setTotalParticipants(completedEmployeeCount);
      setPercentage(Math.round(completionRate * 100));
      setRawDate(new Date(updatedAt));
    }
  }, [data]);

  const hasUncompletedAssessments = useMemo(
    () =>
      data &&
      data.assessmentCycle.participationResults.completedEmployeeCount <
        config.organisationEmployeesLimit,
    [data],
  );

  return {
    rawDate,
    totalEmployees,
    totalParticipants,
    hasUncompletedAssessments,
    percentage,
    data,
    loading,
    error,
  };
}
